import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

const stlContext = createContext();
const StlProvider = ({ children }) => {
	const [file, setFile] = useState("cube.stl");
	const [boundngCenter, setBoundingCenter] = useState([0, 0, 0]);
	const [boundingSize, setBoundingSize] = useState([0, 0, 0]);
	const [volume, setVolume] = useState(0);
	const [cost, setCost] = useState({
		totalcost: 0,
		PrintingCost: 0,
		materialCost: 0,
	});
	const [loading, setLoading] = useState(false);
	//Disable input field
	const [inputDisable, setInputDisable] = useState(false);

	const [machineType, setMachineType] = useState(0);
	const [btnDisable, setBtnDisable] = useState(true);
	const [selectedMaterial, setSelectedMaterial] = useState("alsi10mg");
	const [selectedLayerThickness, setSelectedLayerThickness] = useState('0.04');
	const [optometCost, setOptometCost] = useState({
		opt_totalcost: 0,
		opt_printingCost: 0,
		materialCost: 0,
	});
	return (
		<stlContext.Provider
			value={{
				file,
				setFile,
				boundngCenter,
				setBoundingCenter,
				cost,
				setCost,
				boundingSize,
				setBoundingSize,
				volume,
				setVolume,
				loading,
				setLoading,
				inputDisable,
				setInputDisable,
				machineType,
				setMachineType,
				btnDisable,
				setBtnDisable,
				selectedMaterial,
				setSelectedMaterial,
				selectedLayerThickness,
				setSelectedLayerThickness,
				optometCost,
				setOptometCost,
			}}
		>
			{children}
		</stlContext.Provider>
	);
};
const useStl = () => useContext(stlContext);
export { useStl, StlProvider };
