import { useEffect, useRef, useState } from "react";
import { FileUploader, GaugeChart } from "../../Components";
import "./freeexplorer.css";
import { StlRender } from "../../Components";
import explorerLogo from "../../Assets/Images/amexplorer_img.png";

export const FreeExplorer = () => {
	const [chartDetails, setChartDetails] = useState({
		title: "SUITABILITY CHART",
		description: `The suitability chart indicates how suitable the part is for 3D printing. At low quantities, almost all parts are economical with 3D printing. At higher quantities, more parts can be made more economical using various techniques.  Check AMCosting for details to get an estimate of the cost of the part.`,
		color: "black",
		textDecoration: "none",
	});

	//file is getting updated from FileLoder.js
	const [gaugeValue, setGaugeValue] = useState(0);

	//Ref to navigate to Gauge Chart
	const chartRef = useRef(null);

	//Data for the Gauge Chart
	const chartData = [
		{ color: "#f87b56", text: "(0 - 20%) Unsuitable" },
		{
			color: "#f4bc35",
			text: "(20 - 40%) Suitable with DfAM (Design for Additive Manufacturing)",
		},
		{ color: "#dbf01a", text: "(40 - 60%) Suitable with Modification" },
		{ color: "#77dc10", text: "(60 - 80%) Suitable" },
		{ color: "#0f9a42", text: "(80 - 100%) Highly Suitable" },
	];

	//Legend below Gauge chart
	const ListItem = ({ text }) => {
		return <li className="list-item">{text}</li>;
	};

	//Chart data when gauge value change
	const GaugeDetailsChange = () => {
		if (gaugeValue === 0)
			setChartDetails({
				title: "SUITABILITY CHART",
				description: `The suitability chart indicates how suitable the part is for 3D printing. At low quantities, almost all parts are economical with 3D printing. At higher quantities, more parts can be made more economical using various techniques.  Check AMCosting for details to get an estimate of the cost of the part.`,
				color: "black",
				textDecoration: "none",
			});

		if (gaugeValue > 0 && gaugeValue <= 20)
			setChartDetails({
				title: "Unsuitable",
				description: `This score indicates that this is quite a simple part and conventional methods are more suitable for manufacturing. At low quantities, it could be made economically with 3D printing.`,
				color: "#f87b56",
				textDecoration: "underline",
			});

		if (20 < gaugeValue && gaugeValue <= 40)
			setChartDetails({
				title: "Suitable with DfAM (Design for Additive Manufacturing)",
				description: `Design for Additive Manufacturing(DfAM) is a technique to design the part to make it suitable for 3D printing. Many parts are designed using the constraints of conventional manufacturing. This score indicates that parts can be made suitable by redesigning with techniques like part consolidation, topology optimization and other new standards of Additive manufacturing.`,
				color: "#f4bc35",
				textDecoration: "underline",
			});

		if (40 < gaugeValue && gaugeValue <= 60)
			setChartDetails({
				title: "Suitable with Modification",
				description: `This score indicates that part can be made suitable by doing some modifications. Some scenarios may include design modifications like adjusting the diameters of internal channels or overhang angles.`,
				color: "#dbf01a",
				textDecoration: "underline",
			});

		if (60 < gaugeValue && gaugeValue <= 80)
			setChartDetails({
				title: "Suitable",
				description: `This score indicates that the part is suitable for 3D printing. Typically, complex structures which have high curvatures are in this category. At high quantities, conventional methods may be more suitable`,
				color: "#77dc10",
				textDecoration: "underline",
			});

		if (80 < gaugeValue && gaugeValue <= 100)
			setChartDetails({
				title: "Highly Suitable",
				description: `This score indicates that even in high quantities, 3D printing is the preferred manufacturing method.`,
				color: "#0f9a42",
				textDecoration: "underline",
			});

		if (gaugeValue > 100)
			setChartDetails({
				title: "Highly Suitable",
				description: `This score indicates that even in high quantities, 3D printing is the preferred manufacturing method.`,
				color: "#0f9a42",
				textDecoration: "underline",
			});
	};

	const List = () => {
		return (
			<ul>
				{chartData.map((item, index) => (
					<ListItem
						key={index + 1}
						index={index}
						color={item.color}
						text={item.text}
					/>
				))}
			</ul>
		);
	};

	useEffect(() => {
		// Document title update
		document.title = "AMExplorer";

		//Favicon manipulation
		const favicon = document.querySelector('link[rel="icon"]');
		favicon.href = "/AMExplorer.ico";
	}, []);

	//To change Chart Details
	useEffect(() => {
		GaugeDetailsChange();
	}, [gaugeValue]);

	return (
		<main className="main-container">
			<section className="hero-section-container">
				<div className="hero-left">
					<div className="hero-left-top">
						<div>
							<img
								style={{ width: "200px", height: "auto", marginTop: "8px" }}
								src={explorerLogo}
								alt="logo"
							></img>
						</div>
						<p>
							Upload the .stl file and get the suitability score to 3D print the
							part. The file is uploaded to our servers temporarily for
							evaluation and not stored by us.
						</p>
					</div>
					<div className="hero-left-bottom">
						<FileUploader
							chartRef={chartRef}
							setGaugeValue={setGaugeValue}
							gaugeValue={gaugeValue}
						/>
					</div>
				</div>
				<div className="hero-right">
					<StlRender />
				</div>
			</section>
			<section className="slant_div"></section>
			<section className="calculation section-container card-cs2">
				<div className="chart-container">
					<div className="gauge-chart">
						<GaugeChart
							value={gaugeValue}
							data={chartData}
						/>
						<div className="chart-left-bottom">
							<List />
						</div>
					</div>

					<div className="chart-right-container">
						<div className="chart-right-details">
							<div
								style={{
									transition: "1.5 ease-in-out",
									color: chartDetails.color,
									textDecoration: chartDetails.textDecoration,
								}}
							>
								{chartDetails.title}
							</div>
							<p>{chartDetails.description}</p>
						</div>
						<div className="chart-right-contact">
							<div>
								<p>
									The suitability score provided is indicative and does not
									capture information like quantity. If you need more
									information on the suitability of parts for 3D printing,
									contact our experts:
								</p>
							</div>
							<div className="contact-btn">
								<a
									href="https://www.intechadditive.com/contact-us.php"
									target="_blank"
									rel="noreferrer"
								>
									<button
										className="contact_us_btn"
										ref={chartRef}
									>
										Contact Us
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};
