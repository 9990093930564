import "./loader.css";

export const Loader = ({ text }) => {
	return (
		<div className="loading-container1">
			<p>{text ? text + " " : ""}</p>
			<div className="loader1"></div>
		</div>
	);
};
