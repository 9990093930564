import { Routes, Route } from "react-router-dom";
import { Navbar } from "./Components";
import { StlProvider } from "./Context/StlContext";
import { FreeExplorer, FreeCosting, HomePage, Error404 } from "./pages";

function App() {
	return (
		<div className="App">
			<Navbar />
			<StlProvider>
				<Routes>
					<Route
						path="/"
						element={<HomePage />}
					/>
					<Route
						path="/AMCosting"
						element={<FreeCosting />}
					/>
					<Route
						path="/AMExplorer"
						element={<FreeExplorer />}
					/>
					<Route
						path="*"
						element={<Error404 />}
					/>
				</Routes>
			</StlProvider>
		</div>
	);
}

export default App;
