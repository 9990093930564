import "./errorpage.css";
import { Link } from "react-router-dom";

export const Error404 = () => {
	return (
		<main className="main-container error-page">
			<h1>404 - Page Not Found</h1>
			<p>We're sorry, the page you requested could not be found.</p>
			<Link to="/">Homepage</Link>
		</main>
	);
};
