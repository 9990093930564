import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useStl } from "../../Context/StlContext";
import "./fileUploader.css";
import { Loader } from "../Loader/Loader";
import { MdError } from "react-icons/md";
import { config } from "../../Constants";
import { FaCloudUploadAlt, FaCheck, FaFileAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const uploadSizeLimit = 9e8;

export const FileUploader = (props) => {
	const [dropzoneActive, setDropzoneActive] = useState(false);
	const location = useLocation();
	const [fileName, setFileName] = useState(
		location.pathname === "/AMCosting"
			? "Upload your File Here"
			: "Select File to Upload"
	);
	const [inputError, setInputError] = useState(false);
	const [errorMessage, setErrorMessage] = useState({
		AlertTitle: "",
		AlertDesc: "",
	});

	const {
		file,
		setFile,
		setCost,
		boundingSize,
		volume,
		loading,
		setLoading,
		inputDisable,
		setInputDisable,
		setMachineType,
		btnDisable,
		setBtnDisable,
		selectedMaterial,
		setSelectedMaterial,
		setOptometCost,
		selectedLayerThickness,
		setSelectedLayerThickness,
	} = useStl();

	//States for AMExplorer upload
	const [progress, setProgress] = useState(null);
	const [fileSize, setFileSize] = useState(null);
	const [status, setStatus] = useState("");
	const [s3linkURL, setS3linkURL] = useState(null);
	const stlUuid = useRef(null);
	const amExplorerUrl = config.url.REACT_APP_AM_EXPLORER;

	// AWS UPLOAD
	const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
	const REGION = process.env.REACT_APP_AWS_REGION;

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	});

	const myBucket = new AWS.S3({
		params: { Bucket: S3_BUCKET },
		region: REGION,
	});
	// AWS UPLOAD END

	const apiUrl = config.url.REACT_APP_AM_COSTING;

	function handleDrop(e) {}

	function handleFileChange(e) {
		e.preventDefault();
		if (e.target.files.length) {
			const newFile = e.target.files[0];

			const fileExtension = newFile.name.split(".").pop();

			if (newFile.name.length >= 13) {
				let splitName = newFile.name.split(".");
				setFileName(splitName[0].substring(0, 14) + "... ." + splitName[1]);
			} else setFileName(newFile.name);

			if (newFile.size > uploadSizeLimit) {
				setInputError(true);
				setErrorMessage({
					AlertTitle: "File size is too large! ",
					AlertDesc: "Please upload file less than 900 MB.",
				});
				setBtnDisable(true);
				setInputDisable(true);

				return;
			} else if (fileExtension.toLowerCase() != "stl") {
				setInputError(true);
				setErrorMessage({
					AlertTitle: "File Type Error: ",
					AlertDesc: "Not a stl file!",
				});
				setBtnDisable(true);
				setInputDisable(false);
				setStatus("");

				return;
			} else {
				setInputError(false);
				setErrorMessage("");
			}

			if (e.target.files[0]) setFile(e.target.files[0]);
			else setFile(null);

			if (file !== e.target.files[0]) {
				setBtnDisable(true);
				setInputDisable(false);
				setLoading(true);
				setStatus("");
				if (location.pathname === "/AMExplorer") {
					setS3linkURL("");
					stlUuid.current = null;
					props.setGaugeValue(0);
				}
			}
		}
	}

	function handleCalculateCost() {
		const payload = {
			volume: volume,
			material: selectedMaterial,
			layerThickness:selectedLayerThickness,
			x: boundingSize[0],
			y: boundingSize[1],
			z: boundingSize[2],
		};
		setLoading(true);
		setStatus("Calculating");
		setBtnDisable(true);
		axios
			.post(apiUrl, payload)
			.then((response) => {
				//amc Cost
				const materialCst = Math.round(response.data.material_cost);
				const amc_cst = Math.round(response.data.amc_cost.total_cost);
				const amc_printingCst = Math.round(
					response.data.amc_cost.addUp3dPrintingCost
				);
				const amcCost = {
					totalcost: amc_cst,
					PrintingCost: amc_printingCst,
					materialCost: materialCst,
				};
				// amoptomet cost
				const amopt_cst = Math.round(response.data.amopt_cost.total_cost);
				const amopt_printingCst = Math.round(
					response.data.amopt_cost.addUp3dPrintingCost
				);
				const amoptCost = {
					opt_totalcost: amopt_cst,
					opt_printingCost: amopt_printingCst,
					materialCost: materialCst,
				};
				setCost(amcCost);
				setOptometCost(amoptCost);
				setMachineType(response.data.machine_type);
				setLoading(false);
				setBtnDisable(false);
				setInputDisable(false);
			})
			.then(() => {
				//Scroll to Calculate Cost component smoothly
				setTimeout(() => {
					props.calculateCostRef.current.scrollIntoView({ behavior: "smooth" });
				}, 1000);
			})
			.catch((err) => console.log(err));
	}

	const handleMaterialChange = (event) => {
		setSelectedMaterial(event.target.value);
	};
	const handleLayerThicknessChange = (event) => {
		setSelectedLayerThickness(event.target.value);
	};

	//Conditional rendering of Components
	const Dropdown = () => {
		return location.pathname === "/AMCosting" ? (
			<div className="dropdown-container">
				<div className="dropdown-component">
					<h2>Select the Material - </h2>
					<select
						className="dropdown-select"
						value={selectedMaterial}
						onChange={handleMaterialChange}
					>
						<option value="alsi10mg">AlSi10Mg</option>
						<option value="cchrome">Cobalt Chrome</option>
						<option value="msi">Maraging Steel 300</option>
						<option value="in625">Nickel Alloy IN625</option>
						<option value="in718">Nickel Alloy IN718</option>
						<option value="ss316l">Stainless Steel 316L</option>
						<option value="ti64">Titanium Ti64</option>
						<option value="ss174ph">Stainless Steel 17-4PH</option>
						<option value="Cu-Cr-zr">Cu-Cr-zr</option>
					</select>
				</div>
				<div className="dropdown-component">
					<h2>Select Layer thickness - </h2>
					<select
						className="dropdown-select"
						value={selectedLayerThickness}
						onChange={handleLayerThicknessChange}
					>
						<option value={0.02} >20 µm </option>
						<option value={0.03} >30 µm </option>
						<option value={0.04} >40 µm </option>
						<option value={0.05} >50 µm </option>
						<option value={0.06} >60 µm </option>
						<option value={0.07} >70 µm </option>
						<option value={0.08} >80 µm </option>
						<option value={0.09} >90 µm </option>
						<option value={0.1} >100 µm </option>
					</select>
				</div>
			</div>
		) : (
			<>
				{status === "uploading" ? (
					<div className="progress-area">
						<FaFileAlt
							color="#6990F2"
							size={30}
						/>
						<div className="content">
							<div className="details">
								<span className="name">{fileName} • Uploading </span>
								<span className="percent">{progress}%</span>
							</div>
							<div className="progress-bar">
								<div
									className="progress"
									style={{ width: `${progress}%` }}
								></div>
							</div>
						</div>
					</div>
				) : status === "uploaded" || status === "Analyzing..." ? (
					<div className="uploaded-area">
						<FaFileAlt
							color="#6990F2"
							size={30}
						/>
						<div className="details">
							<span className="name">{fileName} • Uploaded</span>
							<span className="size">{fileSize}</span>
						</div>
						<FaCheck
							size={20}
							color="#6990F2"
						/>
					</div>
				) : (
					<> </>
				)}
			</>
		);
	};

	//Handle Upload in AMExplorer
	const handleUpload = (file) => {
		if (fileName !== "Select File to Upload") {
			if (s3linkURL === "") {
				setInputDisable(true);
				setBtnDisable(true);
				setLoading(true);
				setInputError(false);
				setStatus("please wait");

				stlUuid.current = uuidv4();
				const params = {
					Bucket: S3_BUCKET,
					Key: `AMExplorerFreeBucket/${stlUuid.current}/` + file.name,
				};

				myBucket.createMultipartUpload(params, (err, data) => {
					if (err) {
						// Handle error
						console.error("Error creating multipart upload:", err);
						setErrorMessage({
							AlertTitle: err.message,
						});
						setBtnDisable(false);
						setInputDisable(false);
						setLoading(false);
						setInputError(true);
						setStatus("");
						return;
					}

					const { UploadId } = data;
					const partSize = 5 * 1024 * 1024; // 5MB
					const parts = Math.ceil(file.size / partSize);
					const uploadedParts = [];
					const progressArray = [];

					setFileSize(
						Math.ceil(file.size / 1024) > 1024
							? `${(file.size / (1024 * 1024)).toFixed(1)} MB`
							: `${Math.ceil(file.size / 1024)} KB`
					);

					// After uploading all parts and storing the uploaded parts in the 'uploadedParts' array

					const uploadPart = (partNumber, start, end) => {
						const uploadPartParams = {
							...params,
							UploadId,
							PartNumber: partNumber,
							Body: file.slice(start, end),
						};

						myBucket
							.uploadPart(uploadPartParams, (err, data) => {
								if (err) {
									// Handle error
									console.error("Error uploading part:", err);
									return;
								}
								uploadedParts.push({
									ETag: data.ETag,
									PartNumber: partNumber,
								});

								if (uploadedParts.length === parts) {
									completeMultipartUpload(uploadedParts);
								}
							})
							.on("httpUploadProgress", ({ loaded, total }) => {
								// Track progress of the individual part upload
								setStatus("uploading");
								const partProgress = Math.floor((loaded / total) * 100);
								progressArray[partNumber] = partProgress;
								// Calculating concurrent sum of upload of every part to get overall progress
								setProgress(
									(
										progressArray.reduce((prev, curr) => (prev += curr), 0) /
										parts
									).toFixed(1)
								);
							});
					};

					for (let i = 0; i < parts; i++) {
						const start = i * partSize;
						const end = Math.min(start + partSize, file.size);
						uploadPart(i + 1, start, end);
					}

					const completeMultipartUpload = () => {
						setStatus("uploaded");
						uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber);

						const paramsMultiplePartsUpload = {
							...params,
							UploadId: UploadId,
							MultipartUpload: {
								Parts: uploadedParts,
							},
						};

						myBucket.completeMultipartUpload(
							paramsMultiplePartsUpload,
							(err) => {
								if (err) {
									// Handle error
									setErrorMessage({
										AlertTitle: err.message,
									});
									setBtnDisable(false);
									setInputDisable(false);
									setLoading(false);
									setInputError(true);
									setStatus("");
									console.error("Error completing multipart upload:", err);
									return;
								}
								setLoading(true);
								setStatus("Analyzing...");
								setBtnDisable(true);
								const urlParams = { Bucket: S3_BUCKET, Key: params.Key };
								const s3Url = myBucket.getSignedUrl("getObject", urlParams); //Stl bucket link
								setS3linkURL(s3Url);
								//API call here
								//Instead of URL, send stlUuid.
								axios
									.post(amExplorerUrl, {
										uuid: stlUuid.current,
										file: file.name,
									})
									.then((response) => {
										props.chartRef.current.scrollIntoView({
											behavior: "smooth",
										});
										props.setGaugeValue(response.data.results);
										setBtnDisable(false);
										setInputDisable(false);
										setLoading(false);
										setStatus("Analyzed");
										myBucket.deleteObject(params, (err) => {
											if (err) {
												console.error("Error deleting file:", err);
												return;
											}
										});
									})
									.catch((error) => {
										setErrorMessage({
											AlertTitle: error.message,
										});
										setBtnDisable(false);
										setInputDisable(false);
										setLoading(false);
										setInputError(true);
										setStatus("");
									});
							}
						);
					};
				});
			} else {
				props.chartRef.current.scrollIntoView({ behavior: "smooth" });
			}
		}
	};

	return (
		<div>
			<div
				onDragOver={(e) => {
					setDropzoneActive(true);
					e.preventDefault();
				}}
				onDragLeave={(e) => {
					setDropzoneActive(false);
					e.preventDefault();
				}}
				onDrop={(e) => handleDrop(e)}
				className={"dropzone" + (dropzoneActive ? " active" : "")}
				onChange={(e) => handleDrop(e)}
			>
				<input
					type="file"
					onChange={handleFileChange}
					accept=".stl"
					className="uploader-input"
					disabled={inputDisable}
				/>
				{/* Drop your file here */}
				<FaCloudUploadAlt size={30} />
				{fileName}
			</div>
			{inputError && (
				<div className="input-error">
					<MdError
						color="red"
						size={20}
						style={{ marginRight: "8px" }}
					/>
					<span>
						<strong>{" " + errorMessage.AlertTitle}</strong>
						{errorMessage.AlertDesc}
					</span>
				</div>
			)}
			<div className="dropdown">
				<Dropdown />
			</div>
			<div className="upload-btn-container">
				{location.pathname === "/AMExplorer" ? (
					<button
						className="upload-btn custom-font-size"
						disabled={btnDisable}
						onClick={() => handleUpload(file)}
					>
						{loading ? (
							<Loader
								text={
									status === ""
										? "Rendering the file"
										: status === "uploading"
										? "Uploading"
										: status === "Analyzing..."
										? "Analyzing..."
										: "Please Wait"
								}
							/>
						) : status === "Analyzed" ? (
							"Analysis Completed!"
						) : (
							"Upload and Calculate AM Suitability"
						)}
					</button>
				) : (
					<button
						className="upload-btn"
						disabled={btnDisable}
						onClick={handleCalculateCost}
					>
						{loading ? (
							<Loader
								text={
									status === "Calculating"
										? "Calculating..."
										: "Rendering the file"
								}
							/>
						) : (
							"Calculate Part Cost"
						)}
					</button>
				)}

				<p>Please drag your file (.stl) here or click to Upload</p>
			</div>
		</div>
	);
};
