import ReactSpeedometer from "react-d3-speedometer";

export const GaugeChart = ({ value, data }) => {
	if (value > 100) {
		console.log(value);
		value = 99;
	}
	return (
		<ReactSpeedometer
			maxValue={100}
			minValue={0}
			height={300}
			width={500}
			value={value}
			needleTransition="easeQuadIn"
			needleTransitionDuration={1000}
			needleColor="black"
			startColor="#f87b56"
			segments={50}
			maxSegmentLabels={5}
			endColor="#0f9747"
			needleHeightRatio={0.95}
			ringWidth={150}
			currentValueText={`Suitability ${Math.round(value)}%`}
			// segmentColors={data?.map((item) => item.color)}
		/>
	);
};
