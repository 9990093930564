import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { useRef } from "react";
import { useState } from "react";
import { useStl } from "../../Context/StlContext";

function getVolume(geometry) {
	let position = geometry.attributes.position;
	let faces = position.count / 3;
	let sum = 0;
	let p1 = new THREE.Vector3(),
		p2 = new THREE.Vector3(),
		p3 = new THREE.Vector3();
	for (let i = 0; i < faces; i++) {
		p1.fromBufferAttribute(position, i * 3 + 0);
		p2.fromBufferAttribute(position, i * 3 + 1);
		p3.fromBufferAttribute(position, i * 3 + 2);
		sum += signedVolumeOfTriangle(p1, p2, p3);
	}
	return sum;
}

function signedVolumeOfTriangle(p1, p2, p3) {
	return p1.dot(p2.cross(p3)) / 6.0;
}
export const Client = (props) => {
	const size = useRef();
	const bbox = useRef();
	const cntr = useRef();
	const {
		boundngCenter,
		setBoundingCenter,
		setBoundingSize,
		volume,
		setVolume,
		setBtnDisable,
		setInputDisable,
		setLoading,
		setCost,
		setOptometCost,
	} = useStl();

	function buildScene() {
		cntr.current = bbox.current.getCenter(new THREE.Vector3(bbox.current));

		setBoundingCenter([cntr.current.x, cntr.current.y, cntr.current.z]);

		size.current = bbox.current.getSize(new THREE.Vector3(bbox.current));

		setBoundingSize([size.current.x, size.current.y, size.current.z]);

		setBtnDisable(false);
		setInputDisable(false);
		setLoading(false);
		setCost({ totalcost: 0, materialCost: 0, PrintingCost: 0, machineCost: 0 });
		setOptometCost({
			opt_totalcost: 0,
			opt_printingCost: 0,
			materialCost: 0,
		});
	}

	useEffect(() => {
		const loader = new STLLoader();

		const promise = loader.loadAsync(props.url);
		promise
			.then(function (geometry) {
				const material = new THREE.MeshPhongMaterial();
				const mesh = new THREE.Mesh(geometry, material);
				mesh.geometry.center();
				mesh.geometry.computeBoundingBox();
				bbox.current = mesh.geometry.boundingBox;
				buildScene();
			})
			.catch((errors) => console.error("something wrong", errors));
	}, [props.url]);

	const geom = useLoader(STLLoader, props.url);
	geom.center();
	const vo = getVolume(geom);
	setVolume(vo);
	const ref = useRef();

	return (
		<mesh
			geometry={geom}
			ref={ref}
			position={[boundngCenter[0], boundngCenter[1], boundngCenter[2]]}
			scale={1}
		>
			<meshStandardMaterial
				// color="#b3c2dd"
				color="#7f7f7f"
				transparent
				attach="material"
				roughness={1}
				metalness={0.4}
			/>
		</mesh>
	);
};
