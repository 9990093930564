import React from "react";
export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: "" };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true, error: error });
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			// window.location.reload(false);
			alert("Corrupted File ");
			// console.warn(this.state.error);

			//   const reload = () => {
			//     window.location.reload(false);
			//   };
			//   return <>{/* <button onClick={reload}>Back</button> */}</>;
		}
		return this.props.children;
	}
}
