import { useEffect, useRef, useState } from "react";
import { useStl } from "../../Context/StlContext";
import { FileUploader } from "../../Components";
import "./freecosting.css";
import { StlRender, Loader, HorizontalBarLoader } from "../../Components";
import costingLogo from "../../Assets/Images/amcosting_img.png";
import optomet_logo from "../../Assets/Images/optomet_logo.png";
import { MdInfo } from "react-icons/md";

export const FreeCosting = () => {
	//file is getting updated from FileLoder.js
	const { cost, optometCost, loading, machineType } = useStl();
	const [machineName, setMachineName] = useState("");
	const [machineLink, setMachineLink] = useState("");

	//Ref for scrolling
	const calculateCostRef = useRef(null);

	//Price formattng
	const formatPrice = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: "INR",
		currencyDisplay: "symbol",
	});

	//Layer Thickness from Context
	const { selectedLayerThickness } = useStl();

	useEffect(() => {
		//Document Title Update
		document.title = "AMCosting";
	}, []);

	useEffect(() => {
		if (machineType == 1) {
			setMachineName("iFusion150");
			setMachineLink("https://www.intechadditive.com/hardware-sf1.php");
		} else if (machineType == 2) {
			setMachineName("iFusion325");
			setMachineLink("https://www.intechadditive.com/hardware-mf1.php");
		} else if (machineType == 3) {
			setMachineName("iFusion450");
			setMachineLink("https://www.intechadditive.com/hardware-lf1.php");
		} else setMachineName("Incompatible with Intech Machines");
	}, [cost.totalcost]);

	return (
		<main className="main-container">
			<section className="hero-section-container">
				<div className="hero-left">
					<div className="hero-left-top">
						<div>
							<img
								style={{ width: "200px", height: "auto", marginTop: "8px" }}
								src={costingLogo}
								alt="logo"
							></img>
						</div>
						<p>
							Cost Estimator - Upload the .stl file and get the cost to 3D print
							instantly. The file resides in your computer and not uploaded to
							our servers.
						</p>
					</div>
					<div className="hero-left-bottom">
						<FileUploader calculateCostRef={calculateCostRef} />
					</div>
				</div>
				<div className="hero-right">
					<StlRender />
				</div>
			</section>
			<section className="slant_div"></section>
			<section
				className="calculation section-container card-cs2"
				ref={calculateCostRef}
			>
				<div className="total-cost-container">
					<div className="total-cost card-cs1">
						<h2>Part Cost</h2>
						<hr
							color="lightgray"
							style={{ margin: "1rem 0" }}
						/>
						<div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
								}}
							>
								<h2 style={{ fontSize: "large" }}>Total Part Cost -</h2>

								{loading ? (
									<h2 className="costh2">
										<Loader text="Calculating..." />
									</h2>
								) : (
									<h2 className="costh2">
										{formatPrice.format(cost.totalcost)}
									</h2>
								)}
							</div>

							<div
								style={{
									fontSize: "1.4rem",
									backgroundColor: "rgba(206,232,244,0.6)",
									color: "rgb(32, 96, 125)",
									padding: "0.5rem",
									borderRadius: "4px",
									fontWeight: "500",
									marginTop: "1rem",
								}}
							>
								<p
									style={{
										display: "flex",
										alignItems: "center",
										gap: "0.4rem",
									}}
								>
									<MdInfo size={25} />
									{selectedLayerThickness === "40 µm" ? (
										<span>
											Standard parameter set provided for 40 µm layer thickness
										</span>
									) : (
										<span>
											Parameter set for layer thickness available with AM
											Optomet
										</span>
									)}
								</p>
							</div>
						</div>

						<hr
							color="lightgray"
							style={{ margin: "1rem 0" }}
						/>
						{/* <h2 style={{ paddingTop: "7px", paddingLeft: "4px" }}>Details -</h2> */}
						<div className="split_cost_container">
							{loading ? (
								<h2 className="splitCost"> </h2>
							) : (
								<h3 className="splitCost">
									{"Material Cost -  "}
									<span> {formatPrice.format(cost.materialCost)}</span>
								</h3>
							)}
							{loading ? (
								<h2 className="splitCost"> </h2>
							) : (
								<h3 className="splitCost">
									{"Printing Cost - "}
									<span>{formatPrice.format(cost.PrintingCost)}</span>
								</h3>
							)}
						</div>
					</div>
					{/* Optomet cost card */}
					{/* <div className="optomet-card total-cost card-cs1">
						<div className="optomet-logo">
							<div>
								<h2>Total Cost with - </h2>
							</div>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<a
									href="https://amoptomet.intechadditive.com/"
									target="_blank"
								>
									<img
										src={optomet_logo}
										alt="optomet logo"
										className="clickable"
									/>
								</a>
							</div>
						</div>

						{loading ? (
							<h2 className="costh2">
								<Loader text="Calculating..." />
							</h2>
						) : (
							<h2 className="costh2">
								{formatPrice.format(optometCost.opt_totalcost)}
							</h2>
						)}
						<h2 style={{ paddingTop: "7px", paddingLeft: "4px" }}>Details -</h2>

						<div className="split_cost_container">
							{loading ? (
								<h2 className="splitCost"> </h2>
							) : (
								<h3 className="splitCost">
									{"Material Cost -  " +
										formatPrice.format(optometCost.materialCost)}
								</h3>
							)}
							{loading ? (
								<h2 className="splitCost"> </h2>
							) : (
								<h3 className="splitCost">
									{"Printing Cost - " +
										formatPrice.format(optometCost.opt_printingCost)}
								</h3>
							)}
						</div>
						<div
							style={{
								fontSize: "1.4rem",
								backgroundColor: "rgba(206,232,244,0.6)",
								color: "rgb(32, 96, 125)",
								padding: "0.5rem",
								borderRadius: "4px",
								fontWeight: "500",
								marginTop: "1rem",
							}}
						>
							<p
								style={{
									display: "flex",
									alignItems: "center",
									gap: "0.4rem",
								}}
							>
								<MdInfo size={25} />
								{selectedLayerThickness === "40 µm" ? (
									<span>
										Standard parameter set provided for 40 µm layer thickness
									</span>
								) : (
									<span>
										Parameter set for layer thickness available with AM Optomet
									</span>
								)}
							</p>
						</div>
					</div> */}
					<div
						className="recommended-machine-card card-cs1"
						ref={calculateCostRef}
					>
						<h2>Recommended Machine</h2>
						<hr
							color="lightgray"
							style={{ margin: "1rem 0" }}
						/>
						<div className="machine_name_container">
							<h2 style={{ marginTop: "1.2rem", padding: "1rem 0" }}>
								Metal 3D Printer -{" "}
								{cost.totalcost == 0 ? (
									""
								) : loading ? (
									<HorizontalBarLoader />
								) : (
									machineName
								)}
							</h2>
							{cost.totalcost == 0 ? (
								<div style={{ margin: "2rem", color: "white" }}>Empty</div>
							) : (
								<a
									href={machineLink}
									target="_blank"
								>
									{loading ? (
										<HorizontalBarLoader />
									) : (
										<button
											style={{ marginTop: "8px" }}
											className="contact_us_btn"
										>
											{machineName}
										</button>
									)}
								</a>
							)}
						</div>
						<hr
							color="lightgray"
							style={{ margin: "1rem 0", height: "1px" }}
						/>
						<div className="contact_us_btn_container">
							<div>
								<p>
									The price displayed here is indicative. AMOptomet enables
									higher productivity by changing layer thickness which could
									impact surface roughness. For more information and accurate
									quote contact our experts .
								</p>
							</div>
							<div>
								<a
									href="https://www.intechadditive.com/contact-us.php"
									target="_blank"
								>
									<button className="contact_us_btn">Contact Us</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};
