import "./navbar.css";
// import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/Brand/Intech_logo.png";
import { VscTriangleDown } from "react-icons/vsc";
export const Navbar = () => {
	const location = useLocation();

	const handleLogin = () => {
		window.location.replace('https://dev-auth.intechadditive.com');
	}

	const ConditionalNavRight = () => {
		return location.pathname === "/" ? (
			<div className="navbar-right">
				<div className="navbar-dropdown">
					<div className="navbar-dropdown-header">
						Softwares <VscTriangleDown />
					</div>
					<div className="navbar-dropdown-menu">
						{/* <Link to="/AMCosting"> */}
						<a href="/AMCosting">
							<img
								src="/logo.png"
								alt="AMC"
							/>
							<div className="navbar-dropdown-menu-header">
								<h1>AMCosting</h1>
								<h4>Calculate Cost Per Part</h4>
							</div>
						</a>
						{/* </Link> */}
						<a href="/AMExplorer">
							<img
								src="/AMExplorer.ico"
								alt="AME"
							/>
							<div className="navbar-dropdown-menu-header">
								<h1>AMExplorer</h1>
								<h4>Calculate Suitabilty</h4>
							</div>
						</a>
					</div>
				</div>
				<div className="login-signup">
					<button className="log-in btn" onClick={handleLogin}>Login</button>
					{/* <button className="sign-up btn">Sign Up</button> */}
				</div>
			</div>
		) : (
			<></>
		);
	};
	return (
		<nav className="main-nav">
			<div className="logo">
				<Link to="/">
					<img src={logo}></img>
				</Link>
			</div>
			<ConditionalNavRight />
			{/* <div className="menu-links">
				<ul>
					<li>
                        <a href="#">Home</a>
                    </li>
                    <li>
                        <a href="#">Services</a>
                    </li>
                    <li>
                        <a href="#">About</a>
                    </li>
                    <li>
                        <a href="#">Home</a>
                    </li>
				</ul>
			</div> */}
		</nav>
	);
};
