// Constants.js
const prod = {
	url: {
		REACT_APP_AM_COSTING: "https://dev.intechadditive.com/calc",
		REACT_APP_AM_EXPLORER: "https://dev.intechadditive.com/complexity",
	},
};

const staging = {
	url: {
		REACT_APP_AM_COSTING: "https://dev.intechadditive.com/calc",
		REACT_APP_AM_EXPLORER: "https://dev.intechadditive.com/complexity",
	},
};

const dev = {
	url: {
		REACT_APP_AM_COSTING: "http://localhost:4000/calc",
		REACT_APP_AM_EXPLORER: "https://dev.intechadditive.com/complexity",
		// REACT_APP_AM_EXPLORER: "http://localhost:8000/complexity",
	},
};
export const config = process.env.NODE_ENV === "development" ? dev : process.env.NODE_ENV === "staging" ? staging: prod;
