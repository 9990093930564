import { Suspense, useEffect, useState } from "react";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import { Loader } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { Client } from "../Client/Client";
import "./stlRender.css";
import { useStl } from "../../Context/StlContext";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
const CameraController = () => {
	const { camera, gl } = useThree();
	const { boundngCenter } = useStl();
	useEffect(() => {
		const controls = new OrbitControls(camera, gl.domElement);

		controls.minDistance = 3;
		controls.maxDistance = 20;
		// camera.lookAt(boundngCenter[0],boundngCenter[1],boundngCenter[2]);
		return () => {
			controls.dispose();
		};
	}, [camera, gl, boundngCenter]);
	return null;
};

export const StlRender = (props) => {
	const [stlfile, setStlFile] = useState("cube.stl");
	const { boundngCenter } = useStl();
	const { file } = useStl();
	useEffect(() => {
		let url = "";
		if (file !== "cube.stl") {
			url = window.URL.createObjectURL(file);
			setStlFile(url);
		}
	}, [file]);

	useLoader(STLLoader, stlfile);

	return (
		<div className="stl-main-container">
			<div className="stl-viewser-container">
				<Canvas
					style={{
						height: "100%",
						border: "1px solid lightgrey",
						background: "#fff",
						// background: "linear-gradient(to right, #232526, #414345)",
					}}
					orthographic
					dpr={[1, 2]}
					camera={{
						position: [100, 100, 100],
						fov: 35,
						near: -10000,
						far: 10000,
					}}
				>
					{/* <gridHelper args={[200, 20, 0xff0000, "teal"]} /> */}
					<Suspense fallback={<Loader />}>
						<ambientLight intensity={0.75} />
						<Client url={stlfile} />
					</Suspense>
					{/* Orbit controls */}
					<CameraController />
					<spotLight
						intensity={1}
						angle={0.1}
						penumbra={1}
						position={[450, 350, 20]}
					/>
					<spotLight
						position={[50, 50, 10]}
						angle={0.15}
						penumbra={1}
					/>
					<spotLight
						intensity={1}
						angle={2.5}
						penumbra={1}
						position={[250, 150, 40]}
					/>
					<spotLight
						intensity={0.5}
						angle={0.1}
						penumbra={1}
						position={[-450, -350, -20]}
					/>
					<spotLight
						intensity={0.5}
						angle={2.5}
						penumbra={1}
						position={[-250, -150, -40]}
					/>

					{/* <axesHelper args={[500]} /> */}
				</Canvas>
			</div>
		</div>
	);
};
export default StlRender;
